import styled, { css } from 'styled-components';

export const ChildTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: ${(props) => props.theme.config.spacing.spacing_2};
  width: ${(props) => props.theme.config.spacing.spacing_5};
  height: ${(props) => props.theme.config.spacing.spacing_5};

  &:hover {
    cursor: pointer;
    opacity: 1;
    background: ${(props) => props.theme.config.colors.primary_200};
    padding: ${(props) => props.theme.config.spacing.spacing_2};
    border-radius: 100px;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    svg {
      opacity: 1;

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  ${({ error }) =>
    !!error &&
    css`
      &:hover {
        background: ${(props) => props.theme.config.colors.tertiary_200};
        svg {
          opacity: 1;

          path {
            fill: ${(props) => props.theme.config.colors.tertiary};
          }
        }
      }
    `};
`;
