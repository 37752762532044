import { getSubmissionsRoute } from './getSubmissionsRoute';

export const getExerciseAnswersRoute = (exercise, submissionFiltersString) => {
  const exerciseCategory = exercise?.category_tag?.toLowerCase() || 'full';
  const isInvalid = exercise?.incident_level?.toLowerCase() === 'invalid';

  return `${getSubmissionsRoute(isInvalid)}/${exerciseCategory}/${
    exercise.id
  }/answers?${submissionFiltersString || ''}`;
};
