import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

const ThWrapper = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.config.colors.text_base};
  white-space: nowrap;

  ${(props) =>
    props.centerTr &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.sort &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${(props) =>
        props.theme.config.colors[props.color] || props.color};
    `}

  ${(props) =>
    props.titleAsIcon &&
    css`
      color: ${(props) => props.theme.config.colors.gray_400};
      font-size: 1.7rem;
    `}

  svg {
    margin-left: ${(props) => props.theme.config.spacing.spacing_1};

    ${(props) =>
      props.iconTitle &&
      css`
        margin-right: ${(props) => props.theme.config.spacing.spacing_1};
        width: 1.6rem;
      `}

    &:last-child {
      ${(props) =>
        props?.sort === 'ASC' &&
        css`
          transform: rotate(180deg);
        `}
    }
  }

  ${(props) =>
    props.hoverColor &&
    css`
      &:hover {
        color: ${(props) =>
          props.theme.config.colors[props.hoverColor] || props.hoverColor};
      }
    `}

  ${(props) =>
    props.hoverIconColor &&
    css`
      &:hover {
        svg {
          &::before {
            color: ${(props) =>
              props.theme.config.colors[props.hoverIconColor] ||
              props.hoverIconColor};
          }
        }
      }
    `}
`;

const Tr = styled.tr`
  position: relative;
  color: ${(props) => props.theme.config.colors.text_base};
  background-color: ${(props) => props.theme.config.colors.light};
  border-radius: 0.9rem;
  transition: box-shadow 0.3s ease-in-out;
  height: 6rem;

  &:hover {
    box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  }

  td {
    &:first-child {
      ${(props) => props.tdFirstChildStyle && props.tdFirstChildStyle};
    }
  }

  ${(props) => (props.toggleChild ? 'z-index: auto' : '')};

  ${(props) =>
    props.isProfileDropdownOpen &&
    css`
      opacity: 0.56;
    `};

  ${(props) =>
    props.isInfoTrDisplayed &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      td {
        &:first-child {
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-bottom-right-radius: 0;
        }
      }

      td:before {
        content: '';
        position: absolute;
        bottom: -0.25rem;
        right: 0;
        left: 0;
        border: 1px solid white;
        pointer-events: none;
      }

      td:after {
        content: '';
        position: absolute;
        bottom: -2px;
        right: 0;
        left: 0;
        border: 1px solid white;
        pointer-events: none;
      }
    `}

  ${(props) =>
    props.isToggled &&
    css`
      height: 5rem;
      color: ${(props) => props.theme.config.colors.light};

      && svg {
        opacity: 1;

        path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }

      label span#slider {
        opacity: 0.15;
      }

      & input:checked + span:last-child {
        background: ${(props) => props.theme.config.colors.green};
      }

      & label input:not(:checked) ~ span {
        background: ${(props) => props.theme.config.colors.light};
      }

      td {
        position: relative;
        background: ${(props) => props.theme.config.colors.primary_700};
        padding: 0.8rem 2.4rem;
        z-index: ${ZIndexes.overlay};

        &:last-child {
          padding: 0.8rem 1.6rem;
        }
      }
    `}
`;

const PaginationContainer = styled.div`
  margin-top: ${(props) => props.theme.config.spacing.spacing_6};
`;

const TableContainer = styled.div`
  display: block;
  content: '${(props) => props.customTableWidth}';

  @media ${(props) =>
      props.customTableWidth
        ? `(max-width: ${props.customTableWidth})`
        : device.customTableWidth} {
    ${(props) => !props.withoutBottomScroll && 'overflow-x: auto'};
  }

  ${(props) =>
    props.selectable &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;

      td:first-of-type > div {
        line-height: 3.2rem;
      }
    `};
`;

export { Tr, PaginationContainer, TableContainer, ThWrapper };
