import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const InputField = styled.input`
  ${(props) => props.theme.config.typograph.xsmall};
  display: block;
  padding: 1rem 1.6rem;
  width: 100%;
  height: 4.8rem;
  border: none;
  background: ${(props) => props.theme.config.colors.light};
  border-radius: 0.6rem;
  outline: none;
  color: ${(props) => props.theme.config.colors.text_base};
  appearance: none;
  position: relative;
  outline: none;
  border: 1px solid transparent;
  transition: 0.2s ease-in;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.config.colors.primary};
  }

  ${(props) =>
    props.touched &&
    props.errors &&
    css`
      border-color: ${(props) => props.theme.config.colors.tertiary} !important;
    `}

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: auto;
    outline: none;
  }

  ${(props) =>
    props.PrependIcon &&
    css`
      &&& {
        padding-left: 4rem;
      }
    `}
`;

export const Container = styled.div`
  display: block;
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    padding: 2rem;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    width: 2rem;
    opacity: 0.5;

    &::before {
      fill: ${(props) => props.theme.config.colors.gray_300};
    }

    &.EyeIcon,
    &.EyeNotIcon {
      pointer-events: all;
      right: 14px;

      @media ${device.tabletAndMobile} {
        path {
          fill: ${(props) => props.theme.config.colors.text_base} !important;
        }
      }
    }

    &.LockIcon {
      cursor: default;
    }
  }

  ${(props) =>
    props.PrependIcon &&
    css`
      &&& {
        svg:not(.EyeIcon, .EyeNotIcon, .KeyIcon) {
          right: 0;
          left: 8px;
        }
      }
    `}

  ${(props) =>
    props.suggestPassword &&
    css`
      &&& {
        svg {
          &.EyeIcon,
          &.EyeNotIcon {
            right: 56px;
          }

          &.KeyIcon {
            width: 3.2rem;
            right: 8px;
            border-left: 1px solid
              ${(props) => props.theme.config.colors.gray_200};
          }
        }
      }
    `}
`;
