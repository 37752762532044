import React from 'react';
import PropTypes from 'prop-types';
import * as IconList from '@edusynch/edusynch-svg-icons';

const ALL_CAPS_ICONS = ['pdf'];

function convertToPascalCase(text) {
  if (!text) return '';

  if (ALL_CAPS_ICONS.includes(text)) {
    return `${text?.toUpperCase()}Icon`;
  }

  const words = text.split(/-|\s/);

  const transformedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return `${transformedWords.join('')}Icon`;
}

const Icon = ({ name, size, fontSize, color, ...props }) => {
  const iconName = convertToPascalCase(name);
  const IconComponent = IconList[iconName];

  if (!IconComponent) {
    console.warn(`Icon component not found for name: ${name}`);
    return null;
  }

  const classMap = {
    cursor: 'cursor',
    center: 'center',
    hasHover: 'hasHover',
    left: 'left',
    right: 'right',
  };

  const dynamicClasses = Object.keys(props).reduce((acc, prop) => {
    if (classMap[prop]) {
      acc += ` ${classMap[prop]}`;
    }
    return acc;
  }, '');

  const classes = `edu-icon-${name} ${dynamicClasses}`.trim();

  return (
    <IconComponent
      {...props}
      color={color}
      width={size || fontSize}
      height={size || fontSize}
      className={classes}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  size: PropTypes.string,
  left: PropTypes.bool,
  fontSize: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;
