import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { useMediaQuery } from 'modules/Core/Hooks';
import defaultLogo from 'assets/common/logo-edusynch.png';
import { SidebarLogo } from './SidebarLogo';
import { SidebarLink } from './SidebarLink';
import { SidebarUnclickableLink } from './SidebarUnclickableLink';
import SidebarSubmenu from './SidebarSubmenu.component';
import { useLayout } from '../Hooks/useLayout';
import { ArrowDownIcon, ArrowUpIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './Sidebar.style';
import { RCTooltip } from 'modules/Core/Common';

const Sidebar = (props) => {
  const {
    logo,
    routes,
    opened,
    clicked,
    activeMenuName,
    openSidebarAbsolute,
    setOpenDashboardShortcut,
    closeSidebarMouseLeave,
    openedResponsive,
    toggleResponsiveSidebarOpen,
    toggleResponsiveSidebarClose,
    toggleCloseSidebar,
    toggleSidebarDeactivated,
  } = props;

  const asideRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const largeHeight = useMediaQuery('(min-height: 1180px)');
  const [toggleArrow, setToggleArrow] = useState('top');
  const [showArrow, setShowArrow] = useState(false);
  const { topMargin } = useLayout();
  // const [menuInfo, setMenuInfo] = useState({});

  useEffect(() => {
    const { current } = asideRef;

    if (!window || !current) return;

    let hide = current.scrollHeight === current.clientHeight;

    const onToggleArrow = (condition) => {
      if (condition) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    onToggleArrow(hide);

    const onResize = () => {
      const hide = current.scrollHeight === current.clientHeight;
      onToggleArrow(hide);
    };

    window.addEventListener('resize', onResize, true);
    return () => window && window.removeEventListener('resize', onResize, true);
  }, [showArrow, largeHeight]);

  useEffect(() => {
    const scrollToBottom = (event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;
      const topDistance = Math.abs(scrollHeight - clientHeight - scrollTop);

      if (topDistance < 1) {
        setToggleArrow('bottom');
      } else if (clientHeight + topDistance === scrollHeight) {
        setToggleArrow('top');
      } else {
        setToggleArrow('middle');
      }
    };

    if (asideRef?.current) {
      asideRef.current.addEventListener('scroll', scrollToBottom);
    }

    return () => {
      if (asideRef?.current) {
        asideRef.current.removeEventListener('scroll', scrollToBottom);
      }
    };
  }, [asideRef]);

  useEffect(() => {
    if (isDesktop) toggleResponsiveSidebarOpen();
    else {
      toggleResponsiveSidebarClose();
      toggleCloseSidebar();
      toggleSidebarDeactivated();
      setOpenDashboardShortcut(false);
    }
  }, [isDesktop]);

  const closeSidebarMenu = useCallback(() => {
    if (opened) setOpenDashboardShortcut(true);
    if (!isDesktop) toggleResponsiveSidebarClose();
    closeSidebarMouseLeave();
  }, [opened, isDesktop]);

  const toTop = () => {
    asideRef.current.scrollTo(0, 0);
  };

  const toBottom = () => {
    asideRef.current.scrollTo(0, Number.MAX_SAFE_INTEGER);
  };

  return (
    <S.Container
      onMouseLeave={isDesktop && clicked ? closeSidebarMouseLeave : null}
    >
      <S.SidebarCSSTransition
        in={openedResponsive}
        timeout={200}
        unmountOnExit
        nodeRef={asideRef}
        classNames="sidebar-animation"
      >
        <>
          {!showArrow && (
            <S.MoreItems state={toggleArrow} up onClick={toTop}>
              {isDesktop && <SidebarLogo logo={logo || defaultLogo} />}
              <ArrowUpIcon color="#AACEF2" width={12} height={12} />
            </S.MoreItems>
          )}

          <S.AsideContainer>
            {isDesktop && (
              <RCTooltip anchorSelect=".anchor-element" offset={25} />
            )}

            <S.Aside ref={asideRef} style={{ top: `${topMargin || 0}px` }}>
              {isDesktop && <SidebarLogo logo={logo || defaultLogo} />}

              <S.SidebarList>
                {routes
                  .filter((route) => !route.hidden && route.show_on_menu)
                  .map((items, index) => (
                    <S.SidebarListItem key={uuid()} active={index === 0}>
                      {!items.path ? (
                        <SidebarUnclickableLink
                          {...items}
                          isDesktop={isDesktop}
                          onClick={openSidebarAbsolute}
                          activeMenuName={activeMenuName}
                        />
                      ) : (
                        <SidebarLink
                          {...items}
                          onClick={closeSidebarMenu}
                          isDesktop={isDesktop}
                        />
                      )}
                    </S.SidebarListItem>
                  ))}
              </S.SidebarList>
            </S.Aside>
          </S.AsideContainer>

          {!showArrow && (
            <S.MoreItems state={toggleArrow} down onClick={toBottom}>
              <ArrowDownIcon color="#AACEF2" width={12} height={12} />
            </S.MoreItems>
          )}
        </>
      </S.SidebarCSSTransition>

      <SidebarSubmenu {...props} />
    </S.Container>
  );
};

Sidebar.defaultProps = {
  opened: false,
};

Sidebar.propTypes = {
  routes: PropTypes.array,
  opened: PropTypes.bool,
  clicked: PropTypes.bool,
  logo: PropTypes.string,
  openSidebarAbsolute: PropTypes.func,
  activeMenuName: PropTypes.string,
  closeSidebarMouseLeave: PropTypes.func,
  openedResponsive: PropTypes.bool,
  toggleResponsiveSidebarOpen: PropTypes.func,
  toggleResponsiveSidebarClose: PropTypes.func,
  setOpenDashboardShortcut: PropTypes.func,
  toggleCloseSidebar: PropTypes.func,
  toggleSidebarDeactivated: PropTypes.func,
};

export default React.memo(Sidebar);
