import { useTheme } from 'styled-components';
import { Stack, Skeleton, Divider } from '@mui/material';

export function TableToolbarPlaceholder() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: {
          xs: 'auto',
          sm: 60,
        },
        p: 2,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: {
          sm: 'center',
        },
        gap: {
          xs: 2,
          sm: 0,
        },
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottom: '2px solid',
        borderBottomColor: theme.config.colors.primary_200,
        bgcolor: theme.config.colors.primary_100,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Skeleton
          variant="text"
          width={64}
          height={16}
          sx={{
            transform: 'unset',
            borderRadius: '1.6rem',
            bgcolor: theme.config.colors.primary_200,
          }}
        />
        <Divider
          flexItem
          orientation="vertical"
          sx={{ height: '2.4rem', bgcolor: theme.config.colors.gray_300 }}
        />
        <Skeleton
          variant="text"
          width={256}
          height={16}
          sx={{
            transform: 'unset',
            borderRadius: '1.6rem',
            bgcolor: theme.config.colors.primary_200,
          }}
        />
      </Stack>
    </Stack>
  );
}
