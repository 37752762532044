const createGroupModel = (group = {}) => {
  return {
    id: group.id || null,
    name: group.name || '',
    slug: group.slug || '',
    hideAnalysis: group.hide_analysis || false,
    hideCustomTests: group.hide_custom_tests || false,
    roundedCert: group.rounded_cefr_certificate || false,
    hasSimulations: group.has_simulations || false,
    showCEFRLanguage: group.show_cefr_language || false,
    contentType: group.content_type?.id || '',
    hideQrCodeCertificate: group.hide_qr_code_certificate || false,
    hideCategoriesScoreCertificate:
      group.hide_categories_score_certificate || false,
    scoreControl: group.score_control || false,
  };
};

const createGroupRequestModel = (group = {}) => {
  return {
    id: group.id,
    name: group.name,
    slug: group.slug,
    hide_analysis: group.hideAnalysis,
    hide_custom_tests: group.hideCustomTests,
    rounded_cefr_certificate: group.roundedCert,
    has_simulations: group.hasSimulations,
    show_cefr_language: group.showCEFRLanguage,
    content_type_id: group.contentType,
    hide_qr_code_certificate: group.hideQrCodeCertificate,
    hide_categories_score_certificate: group.hideCategoriesScoreCertificate,
    score_control: group.scoreControl,
  };
};

export { createGroupModel, createGroupRequestModel };
