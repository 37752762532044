import styled, { css } from 'styled-components';

const getSize = (props) => {
  if (props.small) {
    return css`
      width: 43px;
      min-height: 28px;
    `;
  } else if (props.large) {
    return css`
      width: 110px;
      min-height: 32px;
    `;
  } else if (props.full) {
    return css`
      width: 100%;
      min-height: 32px;
    `;
  } else if (props.autoSize) {
    return css`
      width: fit-content;
      min-height: 32px;
      white-space: nowrap;
    `;
  }

  return css`
    width: 90px;
    min-height: 32px;
  `;
};

const getStylesByType = (props) => {
  if (props.secondary) {
    return css`
      background-color: ${(props) => props.theme.config.colors.primary_100};
      color: ${props.theme.config.colors.primary};
    `;
  } else if (props.tertiary) {
    return css`
      background-color: ${(props) => props.theme.config.colors.tertiary_100};
      color: ${props.theme.config.colors.tertiary};
    `;
  } else if (props.duodenary) {
    return css`
      background-color: ${(props) => props.theme.config.colors.orange_100};
      color: ${props.theme.config.colors.orange};
    `;
  } else if (props.nonary) {
    return css`
      background-color: ${(props) => props.theme.config.colors.green_100};
      color: ${props.theme.config.colors.green};
    `;
  } else if (props.decenary) {
    return css`
      background-color: ${(props) => props.theme.config.colors.primary_100};
      color: ${props.theme.config.colors.secondary};
    `;
  }

  return css`
    background-color: ${props.theme.config.colors.gray_100};
    color: ${props.theme.config.colors.text_base};
  `;
};

export const Label = styled.span`
  display: inline-block;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  text-align: center;
  ${(props) => getSize(props)};
  ${(props) => getStylesByType(props)};

  cursor: ${(props) => (props.onClick ? 'pointer' : 'initial')};
`;
