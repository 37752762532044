import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'modules/Core/Common/Table/Table';
import { Icon, Skeleton } from 'modules/Core/Common';
import { TableToolbarPlaceholder } from 'modules/Core/Common/Table/TableToolbar/TableToolbar.placeholder';
import { v4 as uuid } from 'uuid';
import * as S from './ResponseDataHandle.styles';
const UNREAL_DATA = Array.from(Array(8).keys());

const checkboxSkeletonColumn = {
  title: '',
  key: 'checkbox',
  width: '1%',
};

const mountHeader = (columns) =>
  columns.map(({ title, width, sort = false, ...props }) => (
    <th key={title} width={width}>
      <S.ThWrapper {...props}>
        {title}
        {sort && <Icon name="arrow-down --small" color="#0075EA" />}
      </S.ThWrapper>
    </th>
  ));

const mountBody = (columns) =>
  UNREAL_DATA.map(() => {
    const tds = columns.map(({ key, isUser, ...props }) => (
      <td key={uuid()}>
        <Skeleton type={key} isUser={isUser} {...props} />
      </td>
    ));

    return <tr key={uuid()}>{tds}</tr>;
  });

const ResponseDataHandlePlaceholder = ({
  columns,
  showPaginationCount = true,
  selectable = false,
  ...props
}) => {
  const parsedColumns = useMemo(() => {
    return selectable ? [checkboxSkeletonColumn, ...columns] : columns;
  }, [columns, selectable]);

  return (
    <>
      {!!selectable && <TableToolbarPlaceholder />}
      <S.TableContainer selectable={selectable}>
        <Table selectable={selectable} {...props}>
          <thead>
            <tr>{mountHeader(parsedColumns)}</tr>
          </thead>

          <tbody>{mountBody(parsedColumns)}</tbody>
        </Table>
      </S.TableContainer>
      <S.Pagination showPaginationCount={showPaginationCount}>
        {showPaginationCount && <S.PaginationCount />}
        <S.PaginationItems>
          <S.PaginationButton />
        </S.PaginationItems>
      </S.Pagination>
    </>
  );
};

ResponseDataHandlePlaceholder.propTypes = {
  columns: PropTypes.array.isRequired,
  showPaginationCount: PropTypes.bool,
  selectable: PropTypes.bool,
};

export default ResponseDataHandlePlaceholder;
