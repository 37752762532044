import { useEffect, useState, useCallback } from 'react';

export function useSelectableList(data) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (!data?.length) return;

    setSelected([]);

    return () => {
      setSelected([]);
    };
  }, [data]);

  const handleSelectAll = useCallback((items) => {
    setSelected(items);
  }, []);

  const handleClearSelection = useCallback(() => {
    setSelected([]);
  }, []);

  const handleSelectItem = useCallback((item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  }, []);

  return {
    selected,
    handleSelectItem,
    handleSelectAll,
    handleClearSelection,
  };
}
