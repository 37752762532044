import styled, { keyframes, css } from 'styled-components';

const getStyleByType = (props, type) => {
  switch (type) {
    case 'round':
      return `
        max-width: ${
          props.roundExtraLarge
            ? props.theme.config.spacing.layout_6
            : props.roundLarge
            ? props.theme.config.spacing.spacing_7
            : props.theme.config.spacing.spacing_5
        };
        height: ${
          props.roundExtraLarge
            ? props.theme.config.spacing.layout_6
            : props.roundLarge
            ? props.theme.config.spacing.spacing_7
            : props.theme.config.spacing.spacing_5
        };
        border-radius: 50%;
      `;
    case 'string':
      return `
        height: 1.6rem;
        border-radius: 3.8rem;
      `;
    case 'data':
      return `
        height: 1.6rem;
        border-radius: 1.6rem;
      `;
    case 'medium':
      return `
        height: 2.4rem;
        border-radius: 1.6rem;
      `;
    case 'large':
      return `
        height: 3.2rem;
        border-radius: 3.2rem;
      `;
    case 'chart':
      return `
        width: 14.6rem;
        height: 14.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        border: 1.6rem solid ${props.theme.config.colors.gray_200};
        background: transparent;
      `;
    case 'full':
      return `
        width: 100%;
        height: 100%;
        border-radius: 0.8rem;
      `;
    case 'audio':
      return `
        height: 5rem;
        border-radius: 0.6rem;
      `;
    case 'textfield':
      return `
        height: 4.8rem;
        border-radius: 0.6rem;
      `;
    case 'checkbox':
      return `
        width: 3.2rem;
        height: 1.6rem;
        border-radius: 3.2rem;
      `;
    default:
      return '';
  }
};

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const animation = css`
  &::after {
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(-100%);
    animation: ${loading} 1.2s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.4),
      transparent
    );
    z-index: 1;
  }
`;

const Placeholder = styled.span`
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  background: ${(props) =>
    props.secondary
      ? props.theme.config.colors.gray_300
      : props.theme.config.colors.gray_100};

  & + span {
    margin-left: 15px;
  }

  ${(props) => getStyleByType(props, props.type)}
  ${animation}
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.centerTd &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.isUser &&
    css`
      min-width: 20rem;
    `}
`;

export { Placeholder, PlaceholderContainer };
