export const WHITELABEL_TYPES = ['School', 'Unit', 'Group'];

export const WHITELABEL_EDIT_TYPES = [
  'School',
  'Unit',
  'Group',
  'Enterprise Unit',
  'Division',
  'Enterprise',
];

export const WHITELABEL_EDIT_SIDE_MENU = (i18n) => [
  {
    title: i18n.t('modules.whitelabel.edit.form.general'),
    items: [
      {
        title: i18n.t('modules.whitelabel.edit.form.basic-information'),
        slug: 'basic-information',
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.certificate'),
        slug: 'certificate',
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.appearance-theme'),
        items: [
          {
            title: i18n.t('modules.whitelabel.edit.form.brand-colors'),
            slug: 'brand-colors',
          },
          {
            title: i18n.t('modules.whitelabel.edit.form.favicon-images'),
            slug: 'favicon-images',
          },
        ],
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.language'),
        slug: 'language',
        disabled: true,
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.onboarding.title'),
        slug: 'onboarding',
        disabled: false,
      },
    ],
  },
  {
    title: i18n.t('modules.whitelabel.edit.form.settings'),
    items: [
      {
        title: i18n.t('modules.whitelabel.edit.form.general'),
        slug: 'general',
      },
      { title: i18n.t('modules.whitelabel.edit.form.zoom'), slug: 'zoom' },
      {
        title: i18n.t('modules.whitelabel.edit.form.maintenance'),
        slug: 'maintenance',
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.feedback'),
        slug: 'feedback',
      },
      { title: i18n.t('modules.whitelabel.edit.form.email'), slug: 'email' },
      {
        title: i18n.t('modules.whitelabel.edit.form.payment'),
        slug: 'payment',
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.tests'),
        items: [
          {
            title: i18n.t('modules.whitelabel.edit.form.base-tests'),
            slug: 'base-tests',
          },
          {
            title: i18n.t('modules.whitelabel.edit.form.custom-tests'),
            slug: 'custom-tests',
          },
        ],
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.student'),
        slug: 'student',
      },
      {
        title: i18n.t('modules.whitelabel.edit.form.ai-evaluator'),
        slug: 'ai-evaluator',
      },
      { title: i18n.t('modules.whitelabel.edit.form.sso'), slug: 'sso' },
      { title: i18n.t('modules.whitelabel.edit.form.saml'), slug: 'saml' },
    ],
  },
];

export const WHITELABEL_INITIAL_STATE = {
  id: null,
  name: null,
  url: null,
  percentage: null,
  enterprise_id: null,
  division_id: null,
  enterprise_unit_id: null,
  resume_timeout: null,
  offline_time: null,
  api_key_auth: null,
  ranking_name: null,
  email: null,
  tag: null,
  braintree_merchant_id: null,
  braintree_public_key: null,
  braintree_private_key: null,
  link_redirect_sign_up: null,
  enable_mock_test_mode: false,
  payment_url: null,
  cc: null,
  terms: null,
  privacy_policy: null,
  zoom_key: null,
  zoom_secret: null,
  welcome_email_text: null,
  idp_metadata_url: null,
  sign_up_page_simple_text: null,
  scheduler_text: null,
  maintenance_desc: null,
  prep_course_link: null,
  build_name: null,
  type_whitelabel: null,
  institution_id: null,
  plus_edition: false,
  payment_enabled: false,
  usd: false,
  brl: false,
  inr: false,
  mxn: false,
  cefr_enabled: false,
  cop: false,
  custom_enabled: false,
  speed_up_enabled: false,
  nzd: false,
  ielts_master_enabled: false,
  sso_enabled: false,
  sso_sign_up_plan: false,
  has_practice_cefr: false,
  mail_notify_remove_license: false,
  mail_notify_active_license: false,
  mail_invoice_receipt: false,
  mail_mark_project: false,
  mail_sign_up_by_teacher: false,
  mail_sign_up_msg: false,
  mail_forgot_password: false,
  mail_automated: false,
  flag_screen_language: false,
  vnd: false,
  hide_btn_sign_up: false,
  ielts_lrw_enabled: false,
  show_ranking: false,
  toefl_enabled: false,
  toefl_hide: false,
  ielts_academic_enabled: false,
  ielts_academic_hide: false,
  cefr_hide: false,
  toeic_lr_enabled: false,
  toeic_lr_hide: false,
  a2_flyers_enabled: false,
  a2_flyers_hide: false,
  sso_add_plan: false,
  ef: false,
  hide_ielts_bands: false,
  has_pricing_cefr: false,
  has_pricing_a2_flyers: false,
  enable_referral: false,
  hide_analysis_cefr: false,
  hide_analysis_toefl: false,
  hide_analysis_ielts: false,
  hide_analysis_toeic: false,
  hide_analysis_a2_flyers: false,
  hide_reports_cefr: false,
  hide_reports_toefl: false,
  hide_reports_ielts: false,
  hide_reports_toeic: false,
  hide_reports_a2_flyers: false,
  custom_test_enabled: false,
  custom_test_hide: false,
  whitelabel_custom_tests: null,
  flag_screen_language_custom_test: false,
  hide_simulates: false,
  block_mobile_simulation_access: false,
  hide_settings: false,
  hide_forgot: false,
  hide_social_login: false,
  hide_resend_email_verification: false,
  hide_intro_js: false,
  hide_feedback: false,
  saml_enabled: false,
  student_cefr_enabled: false,
  student_ielts_academic_enabled: false,
  student_a2_flyers_enabled: false,
  student_toefl_enabled: false,
  student_toeic_lr_enabled: false,
  student_linguaskill_general_enabled: false,
  student_linguaskill_business_enabled: false,
  use_same_evaluator: false,
  student_custom_test_enabled: false,
  allow_overall_scoring: false,
  show_welcome_email: false,
  cefr_rl_enabled: false,
  cefr_rl_hide: false,
  student_cefr_rl_enabled: false,
  scheduler_activated: false,
  has_practice_cefr_rl: false,
  hide_analysis_cefr_rl: false,
  hide_reports_cefr_rl: false,
  has_pricing_cefr_rl: false,
  sign_up_page_simple: false,
  scheduler: false,
  ielts_full_2_enabled: false,
  ielts_full_2_hide: false,
  student_ielts_full_2_enabled: false,
  students_can_edit_settings: false,
  maintenance_status: false,
  receive_feedback_emails: false,
  rounded_cefr_certificate: false,
  myr: false,
  logo: null,
  logo_certificate: null,
  logo_signature: null,
  open_graph_title: null,
  open_graph_keywords: null,
  open_graph_description: null,
  open_graph_image: null,
  favicon: null,
  authentication_image_desktop: null,
  authentication_image_tablet: null,
  authentication_image_iphone: null,
  hide_analysis_linguaskill_business: false,
  hide_analysis_linguaskill_general: false,
  linguaskill_business_enabled: false,
  linguaskill_business_hide: false,
  linguaskill_general_enabled: false,
  linguaskill_general_hide: false,
  progress_check_enabled: false,
  onboarding_enabled: false,
  page_setup: {
    steps: [],
  },
  hide_practice_after_toefl: false,
  hide_practice_after_ielts: false,
  hide_practice_after_toeic: false,
  hide_practice_after_cefr: false,
  hide_practice_after_cefr_rl: false,
  hide_practice_after_a2_flyers: false,
  ai_evaluator_avatar: null,
  ai_evaluator_name: '',
  ai_evaluator_description: '',
  name_signature: '',
  subtitle_signature: '',

  student_sat_enabled: false,
  sat_enabled: false,
  sat_hide: false,
  hide_analysis_sat: false,
  hide_reports_sat: false,
  hide_practice_after_sat: false,

  student_placement_test_enabled: false,
  placement_test_enabled: false,
  placement_test_hide: false,
  hide_analysis_placement_test: false,
  hide_reports_placement_test: false,
  has_pricing_placement_test: false,

  mcer_enabled: false,
  has_practice_mcer: false,
  hide_analysis_mcer: false,
  hide_practice_after_mcer: false,
  has_pricing_mcer: false,
  mcer_hide: true,
  student_mcer_enabled: false,
  rounded_mcer_certificate: false,
  hide_reports_mcer: false,

  show_validity_legend: false,
  validity_legend: '',

  whitelabel_theme: {
    auth_background: '#003871',
    primary100: '#F2F9FF',
    primary200: '#E5F2FF',
    primary300: '#AACEF2',
    primary400: '#5EA4EA',
    primary500: '#0075EA',
    primary600: '#005CB8',
    primary700: '#004285',
    primary800: '#00366B',
    primary900: '#002952',
    secondary100: '#ABC1D6',
    secondary200: '#7197BD',
    secondary300: '#4172A3',
    secondary400: '#1C528A',
    secondary500: '#003871',
    secondary600: '#003163',
    secondary700: '#002B57',
    secondary800: '#00254A',
    secondary900: '#001E3D',
    tertiary100: '#FFF2F3',
    tertiary200: '#FFCFD0',
    tertiary300: '#FF9497',
    tertiary400: '#FA7D80',
    tertiary500: '#EC3237',
    tertiary600: '#D42D31',
    tertiary700: '#A12226',
    tertiary800: '#6E171A',
    tertiary900: '#3B0C0E',
    quartenary100: '#E8FAF1',
    quartenary200: '#D1F6E3',
    quartenary300: '#A4EDC6',
    quartenary400: '#8DE8B8',
    quartenary500: '#1BD171',
    quartenary600: '#18B863',
    quartenary700: '#149E55',
    quartenary800: '#0E6B3A',
    quartenary900: '#07381E',
  },
};
