import styled, { keyframes, css } from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: ${(props) => props.theme.config.spacing.layout_7};

  button {
    margin: 1.6rem auto 0;
  }
`;

const Image = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
`;

const Title = styled.h1`
  ${(props) => props.theme.config.typograph.tertiary}
  color: ${(props) => props.theme.config.colors.secondary};
  margin-top: ${(props) => props.theme.config.spacing.spacing_3};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_2};
  opacity: 0.8;
`;

const Description = styled.span`
  ${(props) => props.theme.config.typograph.default}
  color: ${(props) => props.theme.config.colors.text_base};
`;

const ThWrapper = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.config.colors.text_base};

  ${(props) =>
    props.centerTr &&
    css`
      justify-content: center;
    `}

  svg {
    margin-left: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

const PaginationContainer = styled.div`
  margin-top: ${(props) => props.theme.config.spacing.spacing_6};
`;

const TableContainer = styled.div`
  display: block;

  @media ${device.customTableWidth} {
    overflow-x: auto;
  }

  th {
    div {
      white-space: nowrap;
    }
  }

  td {
    > div {
      float: none !important;
    }
  }

  tbody tr {
    color: ${(props) => props.theme.config.colors.text_base};
    background-color: ${(props) => props.theme.config.colors.light};
    border-radius: 0.9rem;
    transition: box-shadow 0.3s ease-in-out;
    height: 6rem;

    &:hover {
      box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    }
  }

  ${(props) =>
    props.selectable &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;

      tr {
        height: 4.8rem;
      }

      td:first-of-type {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }

      td:first-of-type > div {
        line-height: 3.2rem;
      }
    `};
`;

const getStyleByType = (props, type) => {
  switch (type) {
    case 'round':
      return `
        max-width: ${props.theme.config.spacing.spacing_5};
        height: ${props.theme.config.spacing.spacing_5};
        border-radius: 50%;
      `;
    case 'string':
      return `
        height: 1.6rem;
        border-radius: 3.8rem;
      `;
    case 'data':
      return `
        height: 1.6rem;
        border-radius: 1.6rem;
      `;
    case 'medium':
      return `
        height: 2.4rem;
        border-radius: 1.6rem;
      `;
    case 'large':
      return `
        height: 3.2rem;
        border-radius: 3.2rem;
      `;
    default:
      return '';
  }
};

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const animation = css`
  &::after {
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(-100%);
    animation: ${loading} 1.2s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.4),
      transparent
    );
    z-index: 1;
  }
`;

const Placeholder = styled.span`
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  background: ${(props) => props.theme.config.colors.gray_200};

  & + span {
    margin-left: 15px;
  }

  ${(props) => getStyleByType(props, props.type)}
  ${animation}
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const defaultProps = css`
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${(props) => props.theme.config.colors.gray_300};
`;

const PaginationCount = styled.span`
  ${defaultProps}
  max-width: 200px;
  height: 1.6rem;
  border-radius: 3.8rem;
  ${animation}

  &::after {
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.3),
      transparent
    );
  }
`;

const PaginationButton = styled.span`
  min-width: 20.76rem;
  height: 4rem;
  border-radius: 3.2rem;
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  ${defaultProps}
  ${animation}

  &::after {
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.3),
      transparent
    );
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.config.spacing.spacing_6};
  ${({ showPaginationCount }) =>
    !showPaginationCount &&
    css`
      justify-content: center;
    `};
`;

const PaginationItems = styled.div`
  display: flex;

  span + span {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

export {
  Image,
  Title,
  Container,
  Description,
  Placeholder,
  PaginationContainer,
  TableContainer,
  PlaceholderContainer,
  ThWrapper,
  PaginationCount,
  PaginationButton,
  Pagination,
  PaginationItems,
};
