const createSchoolModel = (school = {}) => {
  return {
    id: school.id || null,
    name: school.name || '',
    cnpj: school.cnpj || '',
    groupId: school.group?.id || '',
    unitId: school.unit?.id || '',
    schoolId: school.school?.id || '',
    slug: school.slug || '',
    image: school.image || '',
    hideAnalysis: school.hide_analysis || false,
    hideCustomTests: school.hide_custom_tests || false,
    showOnSignup: school.show_on_signup || false,
    signUpEmailSaml: school.sign_up_email_saml || false,
    showRanking: school.show_ranking || false,
    hasSimulations: school.has_simulations || false,
    contentType: school.content_type?.id || '',
    hidePractice: school.hide_practice || false,
    hideQrCodeCertificate: school.hide_qr_code_certificate || false,
    hideCategoriesScoreCertificate:
      school.hide_categories_score_certificate || false,
    scoreControl: school.score_control || false,
  };
};

const createSchoolRequestModel = (school = {}) => {
  const schoolRequestModel = {
    id: school.id,
    name: school.name,
    cnpj: school.cnpj,
    group_id: school.groupId,
    unit_id: school.unitId,
    school_id: school.schoolId,
    slug: school.slug,
    hide_analysis: school.hideAnalysis,
    hide_custom_tests: school.hideCustomTests,
    show_on_signup: school.showOnSignup,
    sign_up_email_saml: school.signUpEmailSaml,
    show_ranking: school.showRanking,
    has_simulations: school.hasSimulations,
    content_type_id: school.contentType,
    hide_practice: school.hidePractice,
    hide_qr_code_certificate: school.hideQrCodeCertificate,
    hide_categories_score_certificate: school.hideCategoriesScoreCertificate,
    score_control: school.scoreControl,
  };

  if (school.image instanceof File) schoolRequestModel.image = school.image;

  return schoolRequestModel;
};

export { createSchoolModel, createSchoolRequestModel };
