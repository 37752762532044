import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Skeleton } from 'modules/Core/Common';
import {
  WidgetCard,
  WidgetCardContent,
} from 'modules/Dashboard/Widgets/Common';
import { useMediaQuery, MOBILE_SIZE } from 'modules/Core/Hooks';

import * as S from './UsersWidgetPlaceholder.styles';

export const UsersWidgetPlaceholder = ({ widget }) => {
  const widgetSize = widget?.settings?.size;
  const isMobile = useMediaQuery(MOBILE_SIZE);

  return (
    <WidgetCard size={widgetSize}>
      <Row>
        <Col xs={12} md={widgetSize === 'small' ? 12 : 6}>
          <S.Header size={widgetSize}>
            <S.Icon style={{ width: '4.8rem', height: '4.8rem' }}>
              <Skeleton type="round" />
            </S.Icon>
            <div style={{ width: '100%' }}>
              <h6 style={{ width: '75%', marginBottom: '0.4rem' }}>
                <Skeleton type="string" />
              </h6>
              <div style={{ width: '50%' }}>
                <Skeleton type="string" />
              </div>
            </div>
          </S.Header>
        </Col>
        <Col xs={12} md={widgetSize === 'small' ? 12 : 6}>
          <S.SubHeader>
            <S.ContentFilter style={{ width: '50%' }}>
              <h6 style={{ margin: 0 }}>
                <Skeleton type="string" />
              </h6>
            </S.ContentFilter>
            <S.ContentAction>
              <Skeleton type="round" style={{ width: '3.2rem' }} />
            </S.ContentAction>
          </S.SubHeader>
        </Col>
      </Row>
      <WidgetCardContent size={widgetSize}>
        <Skeleton
          type="full"
          style={{
            width: '100%',
            height:
              widgetSize === 'small' ? '20rem' : isMobile ? '21.5rem' : '28rem',
          }}
        />
      </WidgetCardContent>
    </WidgetCard>
  );
};

UsersWidgetPlaceholder.propTypes = {
  widget: PropTypes.object,
};
