import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  width: ${(props) => props.width || '550'}px;

  @media ${device.tabletAndMobile} {
    width: 100%;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.spaceBetween) {
      return css`
        justify-content: space-between;
      `;
    }
  }}
`;

export const ScroolBox = styled.div`
  padding: 2.4rem;
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  overflow-y: scroll;
  max-height: calc(90vh - 190px);

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.secondary_100};
    border-radius: 20px;
    width: 5px;
  }
`;

export const ModalHeader = styled(Flex)`
  padding: 2.4rem;
  justify-content: space-between;
  color: ${(props) => props.theme.config.colors.text_base};
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_100};
`;

export const ModalBody = styled.div`
  padding: 2rem 0 2rem;
`;

export const ModalFooter = styled.div`
  padding: 2rem 2.5rem 3rem 3.4rem;

  button.submit-button {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
`;

export const CustomSection = styled.div`
  padding: 2rem 0 1rem;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 50%;
  width: 56px;
  height: 56px;
`;

export const Close = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const SelectContainer = styled.div`
  padding-bottom: 1.6rem;
`;

export const DateLabel = styled.span`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};
`;

export const DateCard = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.6rem;

  & + div {
    margin-left: ${(props) => props.theme.config.spacing.spacing_5};

    @media (max-width: 1270px) {
      margin-left: 1rem;
    }
  }

  .MuiOutlinedInput-root {
    ${(props) => props.theme.config.typograph.xsmall};
    color: ${(props) => props.theme.config.colors.primary};
    background: rgba(0, 117, 234, 0.05);
    border-radius: 0.4rem;
    border: none;
    padding-left: 0;
    height: 3.2rem;
    padding: 0 1.1rem;
    max-width: 13.3rem;
    cursor: pointer;

    input {
      color: ${(props) => props.theme.config.colors.primary} !important;
      cursor: pointer;
    }

    fieldset {
      border-width: 0px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 0px;
    }
  }

  svg:first-child {
    left: 1.6rem;
    opacity: 0.5;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  svg.ArrowDownIcon {
    display: none;
  }
`;

export const ExportOptions = styled.div`
  .MuiTypography-root {
    font-size: 1.4rem;
  }
`;

export const Toggl = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) => {
    if (props.showAdvanced) {
      return css`
        width: 100%;
      `;
    }
  }}

  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  transition: all 0.15s ease-in-out;

  svg {
    margin-left: 0.3rem;
    width: 1.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  &:hover {
    color: ${(props) => props.theme.config.colors.primary};

    svg path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const Advanced = styled.div`
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};
  max-height: 18.2rem;
  width: 100%;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.config.colors.primary} !important;
  scrollbar-width: thin !important;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.primary_100};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.primary};
    border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.primary};
  }
`;

export const Col = styled.div`
  width: 50%;
`;

export const FooterContainer = styled(Flex)`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.column) {
      return css`
        flex-direction: column;
      `;
    } else if (!props.column && props.flexEnd) {
      return css`
        justify-content: flex-end;
      `;
    }
  }}
`;
