import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import {
  Tooltip,
  ConfirmPopover,
  Icon,
  MUIMenu,
  MUIMenuItem,
} from 'modules/Core/Common';

export const TableAction = ({
  tooltip,
  iconName,
  isConfirm = false,
  isLoading = false,
  confirmSubtitle = 'This action will delete this item.',
  href,
  onClick,
  children,
  tooltipProps = {},
  menuItems,
  ...props
}) => (
  <Tooltip
    placement="bottom"
    overlay={tooltip}
    secondaryTooltip
    disabled={!tooltip}
    overlayInnerStyle={{
      backgroundColor: '#0075EA',
      borderRadius: '4px',
      padding: '8px 16px',
    }}
    {...tooltipProps}
  >
    {isConfirm ? (
      <ConfirmPopover
        onConfirm={onClick}
        isLoading={isLoading}
        subtitle={confirmSubtitle}
        iconButtonProps={{
          sx: {
            width: '3.2rem',
            height: '3.2rem',
          },
        }}
      >
        <IconButton size="small" onClick={onClick} {...props}>
          {children || <Icon name={iconName} size="14px" />}
        </IconButton>
      </ConfirmPopover>
    ) : href ? (
      <Link to={href} style={{ width: '2rem', height: '2rem' }}>
        <Icon name={iconName} size="14px" />
      </Link>
    ) : menuItems ? (
      <MUIMenu>
        {menuItems?.map((menuItem) => (
          <MUIMenuItem key={menuItem.title} onClick={menuItem.onClick}>
            <Icon name={menuItem.iconName} size="16px" />
            {menuItem.title}
          </MUIMenuItem>
        ))}
      </MUIMenu>
    ) : (
      <IconButton size="small" onClick={onClick} {...props}>
        {children || <Icon name={iconName} size="14px" />}
      </IconButton>
    )}
  </Tooltip>
);

TableAction.propTypes = {
  tooltip: PropTypes.string,
  iconName: PropTypes.string,
  isConfirm: PropTypes.bool,
  confirmSubtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  tooltipProps: PropTypes.object,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  children: PropTypes.node,
};
