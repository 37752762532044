import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import { Icon } from 'modules/Core/Common';

export function MUIMenu({ children, ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="mui-menu-button"
        aria-controls={open ? 'mui-menu-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <Icon size="14px" name="more" />
      </IconButton>
      <Menu
        id="mui-menu"
        MenuListProps={{
          'aria-labelledby': 'mui-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={handleClose}
        {...rest}
      >
        {children}
      </Menu>
    </div>
  );
}

MUIMenu.propTypes = {
  children: PropTypes.node,
};
