import styled from 'styled-components';

export const EmailsSent = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.light};
  margin-top: 1rem;
  padding-top: 1rem;
  text-align: center;
`;

export const EmailsSentNumber = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: ${(props) => props.theme.config.colors.gray_300};
  color: ${(props) => props.theme.config.colors.text_base};
  font-size: 0.9rem;
  font-weight: 700;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
