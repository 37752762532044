import PropTypes from 'prop-types';
import i18n from 'i18next';

import { TableAction } from '../../TableAction.component';
const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

export function EditTableAction({ onEdit, ...props }) {
  return (
    <TableAction
      tooltip={i18n.t(`${BUTTON_PREFIX}.edit`)}
      iconName="edit"
      onClick={onEdit}
      {...props}
    />
  );
}

EditTableAction.propTypes = {
  onEdit: PropTypes.func,
};
